import {http} from '~/composables/useVzFetch';
import {IFastOrderRequest, IVipText} from '~/types/api';
import FastOrderForm from '~/common/home/FastOrderForm';
import {EventRecord} from '~/types/components/personal/actions';
import {Article} from "~/types/article";
import {useMemoize} from '@vueuse/core';

export function sendFeedbackVip(body: FastOrderForm): Promise<IFastOrderRequest> {
  return $fetch(
      `${http}/feedback`,
      { method: 'post', body }
  )
}

export function getContactTypes(): Promise<{id: string, name: string}[]> {
  return $fetch(`${http}/contact-type`)
}

export const getVipText = useMemoize(() => {
  return $fetch<IVipText>(`${http}/main/get`, { method: 'POST' })
})

export function getNews(page: number | undefined = undefined, params = {sort: '-date', is_action: 0}):
    Promise<EventRecord[] | null> {
  return $fetch(`${http}/news`, {
    params: {
      hidden: 0,
      page,
      ...params
    }
  })
}

export const getNewsArticle = (params: { href: string, preview?: boolean }, type: Article = 'news'): Promise<any> => {
  return $fetch(`${http}/${type}`, {
    params
  })
}
